import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
// import Disqus from 'disqus-react'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import { Col, Gap, SEO, Title, Container, ArticlesSection, Waves, TapsBg } from '../components'
import { extractNodesFromData } from '../lib/helpers'
import { enhanceContent } from '../lib/content'
import { up } from '../lib/styles'

const ArticleContent = styled.div`
  max-width: 100%;

  ${({ theme: { fonts, colors } }) => css`
    strong {
      font-weight: 700;
    }
    h2 {
      color: #0099fc;
      font-family: ${fonts.venti};
      font-weight: 500;
      font-size: 28px;
      line-height: 42px;
      ${up('mobile')} {
        font-size: 36px;
        line-height: 48px;
      }
    }
    h3 {
      color: #0099fc;
      font-family: ${fonts.venti};
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;
      ${up('mobile')} {
        font-size: 28px;
        line-height: 36px;
      }
    }
    h6 {
      opacity: 0.75;
      color: #324155;
      font-family: ${fonts.nuninto};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: -40px 0 50px 0;
      text-align: center;
    }
    li,
    p,
    .p {
      color: #043e86;
      font-family: ${fonts.nuinto};
      font-size: 20px;
      font-weight: 300;
      line-height: 40px;
    }
    p,
    .p {
      margin-bottom: 29px;
    }
    ul,
    ol {
      margin-bottom: 29px;
      padding-left: 22px;
      p,
      .p {
        margin-bottom: 10px;
      }
    }

    table {
      border-collapse: collapse;
    }

    .table-container {
      position: relative;
      width: 100%;
      overflow: auto;
    }

    table,
    th,
    td {
      border: 1px solid #7575751a;
      border: 1px solid ${colors.backgroundBlue};
    }

    td {
      padding: 10px;
    }

    tr {
      &:first-child {
        background: ${colors.backgroundBlue};
      }
    }

    td {
      p,
      .p {
        font-size: 18px;
        margin-bottom: 0;
        line-height: normal;
      }
    }
  `}
`
const StyledImage = styled(Img)`
  width: 100%;
`

const StyledImageWrapper = styled.div`
  ${({ theme: { pageSidePadding, mobilePageSidePadding } }) => css`
    margin: 50px -${mobilePageSidePadding}px;
    width: calc(100% + ${mobilePageSidePadding * 2}px);

    ${up('mobile')} {
      margin: 50px -${pageSidePadding}px;
      width: calc(100% + ${pageSidePadding * 2}px);
    }
    ${up('tablet')} {
      margin: 50px -40px;
      width: calc(100% + 80px);
    }
  `}
`

// TODO použít Title.Article
// const ArticleTitle = styled(Title.Main)`
//   font-weight: 700;
//   font-size: 40px;
//   line-height: 50px;
//   ${up('mobile')} {
//     font-size: 44px;
//     line-height: 55px;
//   }
// `

const TopImageWrapper = styled.div`
  position: relative;
  width: 100%;
`
const TopImage = styled(Img)`
  width: 100%;
  z-index: -1;
`

class ArticleTemplate extends Component {
  componentDidMount() {
    // TODO
  }

  render() {
    const {
      data,
      location: { pathname, href },
    } = this.props
    const { article, contentImages } = extractNodesFromData(data)

    const contentReactElement = enhanceContent({
      content: article.content,
      contentImages,
      ImageComponent: (props) => (
        <StyledImageWrapper>
          <StyledImage {...props} />
        </StyledImageWrapper>
      ), // custom image component
    })
    const disqusConfig = {
      url: `https://pribehvody.cz/${article.slug}`,
      identifier: article.slug,
      title: article.title,
    }

    return (
      <>
        {/* <CommentCount config={disqusConfig} placeholder="..." /> */}
        <SEO pathname={pathname} title={`${article.title} | Články`} />
        <Gap gap="135px" mobileGap="80px" />

        {article.image ? (
          <>
            <TopImageWrapper>
              <Waves top menu />
              <TopImage fluid={article.image.image.fluid} />
              <Waves />
            </TopImageWrapper>
            <Gap gap="80px" />
          </>
        ) : (
          <>
            <Gap gap="250px" mobileGap="150px" bp="mobile" />
            <TapsBg
              top="240px"
              topMobile="150px"
              left="unset"
              xCount={7}
              yCount={1}
              transparent
              bp="mobile"
            />
          </>
        )}

        <Container>
          <Col maxWidth="720px" width="100%" alignSelf="center">
            <Title.Article>{article.title}</Title.Article>
            <Gap gap="20px" />
            <ArticleContent>{contentReactElement}</ArticleContent>
          </Col>
        </Container>

        <Gap gap="100px" />

        <ArticlesSection
          page="PRICE"
          isArticle
          categories={article.categories}
          currentArticleId={article._id}
        />

        <Gap gap="150px" />
      </>
    )
  }
}

export default ArticleTemplate

export const pageQuery = graphql`
  query($id: String!) {
    article(_id: { eq: $id }) {
      ...ArticleFragment
    }
    contentImages: allImageFile {
      edges {
        node {
          _id
          name
          file {
            ...PostContentImage
            relativePath
          }
        }
      }
    }
  }
`
