import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'
import React, { Component } from 'react'
import Img from 'gatsby-image'

const parseStyles = styles =>
  styles
    .split(';')
    .filter(style => style.split(':')[0] && style.split(':')[1])
    .map(style => [
      style
        .split(':')[0]
        .trim()
        .replace(/-./g, c => c.substr(1).toUpperCase()),
      style.split(':')[1].trim(),
    ])
    .reduce(
      (styleObj, style) => ({
        ...styleObj,
        [style[0]]: style[1],
      }),
      {},
    )

// CONTENT parsing
const htmlToReactParser = new HtmlToReactParser()
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
const getProcessingInstruction = (images, ImageComponent) => [
  {
    // replace p tag with custom component (cuz of gatsby image is div inside p tag, which browsers dont like..)
    shouldProcessNode(node) {
      return node.name === 'p' || node.name === 'table'
    },
    processNode(node, children, index) {
      if (node.name === 'table') {
        return (
          <div className="table-container">
            <table>{children}</table>
          </div>
        )
      }
      return (
        <div
          className="p"
          key={index}
          style={
            node.attribs.style ? parseStyles(node.attribs.style) : undefined
          }
        >
          {children}
        </div>
      )
    },
  },
  {
    // Custom <img> processing
    shouldProcessNode(node) {
      return node.name === 'img'
    },
    processNode(node, children, index) {
      const foundImage = images.find(
        image =>
          node.attribs.src
          && node.attribs.src.indexOf(image.file.relativePath) !== -1,
      )
      if (foundImage && foundImage.file) {
        return (
          <ImageComponent
            key={`${index}${foundImage._id}`}
            alt={foundImage.name}
            fluid={foundImage.file.contentImage.fluid}
          />
        )
      }
      return <img {...node.attribs} />
    },
  },

  {
    // Anything else
    shouldProcessNode(node) {
      return true
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

export function replaceShortCodes({ content, contentImages }) {
  let index = 0
  while (content.indexOf('[img', index) !== -1) {
    index = content.indexOf('[img', index)
    const endIndex = content.indexOf(']', index) + 1
    const shortCode = content.substring(index, endIndex)
    const idIndex = shortCode.indexOf('id="') + 4
    const idEndIndex = shortCode.indexOf('"', idIndex)
    const id = shortCode.substring(idIndex, idEndIndex)

    const imageObj = contentImages.find(im => im._id === id)

    if (imageObj) {
      const tag = `<img src="${imageObj.file.relativePath}" alt="${imageObj.name}"/>`
      content = content.substring(0, index) + tag + content.substring(endIndex)
    }
  }
  return content
}

export const enhanceContent = ({
  content,
  contentImages,
  ImageComponent = Img,
}) => {
  // replacing shortCodes with custom tags
  const postContent = replaceShortCodes({ content, contentImages })

  // replace tags with react components
  const contentReactElement = htmlToReactParser.parseWithInstructions(
    postContent,
    () => true,
    getProcessingInstruction(contentImages, ImageComponent),
  )

  return contentReactElement
}
